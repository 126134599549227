import {
    getProvince,
    getCity
} from '@/service/apis/common'
import {setPoint} from '@/utils'
import {reactive} from 'vue'
import { useStore } from 'vuex'
export default function(){
    const store = useStore()
    const proviceList = reactive([])
    // 获取省
    const doGetPosition = async() => {
       const res = await getProvince();
       for(let i=0;i<res.data.length;i++){
        proviceList.push({
            ...res.data[i],
            value: {code:res.data[i].code,value: res.data[i].name},
            label: res.data[i].name,
        })
       }
       console.log('省',proviceList);
       return proviceList
    }
    // 获取市
    const doGetCity = async(provincecode) => {
        const res = await getCity({provincecode});
        const cityList = reactive([])
        for(let i=0;i<res.data.length;i++){
            cityList.push({
                ...res.data[i],
                value: {code:res.data[i].code,value: res.data[i].name},
                label: res.data[i].name,
                leaf: true //最后一层了
            })
           }
        console.log('市',cityList);
        return cityList
     }
    const handleChange = (data) =>{
       
        store.commit('setPosition', data)
        setPoint(store)

    }
    const config = {
        lazy: true,
        async lazyLoad(node, resolve){
           console.log('node',node)
           const { level } = node
           if(level == 0){
            const res = await doGetPosition()
            resolve(res)
           }else if(level == 1){
            const res = await doGetCity(node.value.code)
            resolve(res)
           }else {
               return 
           }
        }
     }
    doGetPosition()
    
    return {
        proviceList,
        doGetCity,
        handleChange,
        config
    }
}
import { ref } from "vue";
// import { useStore } from "vuex";
import { useLogin } from "@/Hooks";
import store from '@/store'
export default function () {
  // const store = useStore();

  const showPop = (type) => {
    store.commit("setLoginPop", type);
  };
  const closePop = () => {
    store.commit("setLoginPop", 0);

    // console.log(store.state.loginPop,'store'.repeat(100));
    // if (store.state.loginPop === 1) {
    //   const { doLogin } = useLogin();
    //   store.commit("setLoginPop", 0);
    //   doLogin();
    // } else {
    // }
  };
  return {
    showPop,
    closePop,
  };
}

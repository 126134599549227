import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tabbar = _resolveComponent("tabbar");

  var _component_router_view = _resolveComponent("router-view");

  var _component_v_foot = _resolveComponent("v-foot");

  return _openBlock(), _createElementBlock(_Fragment, null, [_ctx.$route.meta.showHeader ? (_openBlock(), _createBlock(_component_tabbar, {
    key: 0
  })) : _createCommentVNode("", true), _createVNode(_component_router_view, null, {
    default: _withCtx(function (_ref) {
      var Component = _ref.Component;
      return [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: _ctx.$route.name
      })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
        key: 0
      })) : _createCommentVNode("", true)];
    }),
    _: 1
  }), _ctx.$route.meta.showFooter ? (_openBlock(), _createBlock(_component_v_foot, {
    key: 1
  })) : _createCommentVNode("", true)], 64);
}
// function isJson(v){
//   let state
//   try{
//     JSON.parse(v)
//     state = true
//   }catch(err){
//     console.log(err);
//     state = false
//   }
//   return state
// }
export default function(k,v){
   if(!v){
    let data = decodeURIComponent(localStorage.getItem(k));
    data =  JSON.parse(data)
    return data
   }else{
     let data = v
     data = encodeURIComponent(JSON.stringify(data))
     localStorage.setItem(k,data)
  }
}
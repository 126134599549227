
export default function(store){
  const myGeo = new BMapGL.Geocoder();
      // 将地址解析结果显示在地图上，并调整地图视野
      myGeo.getPoint(
        store.getters.getPosition,
        function (point) {
          if (point) {
            point.lng = parseFloat(point.lng).toFixed(5)
            point.lat = parseFloat(point.lat).toFixed(5)
            store.commit('setPoint',point)
            console.log(store.state.point,1111111111111111111111);
          } else {
            ElMessage({
              type: 'error',
              message: '您选择的地址没有解析到结果！'
            })
          }
        }
      );
}

export default [
    {
        name: 'h5_p1',
        path: '/h5_p1',
        component: () => import('../views/h5/page1.vue'),
        meta: {
          showHeader: false,
          showFooter: false,
        }
      },
]
import { useRoute, useRouter } from "vue-router";
import userInfo from 'components/Login/userInfo';
export default {
  components: {
    userInfo: userInfo
  },
  setup: function setup() {
    var logo = require("assets/imgs/logo.png");

    var router = useRouter();
    var route = useRoute();

    var toPage = function toPage(path) {
      router.push({
        path: path
      });
    };

    var itemClick = function itemClick(e) {
      console.log(route);
    };

    return {
      logo: logo,
      toPage: toPage,
      itemClick: itemClick
    };
  }
};
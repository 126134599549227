import { reactive, ref, computed, nextTick } from "vue";
import { FILE_URL } from "@/config";
import { ElMessage } from "element-plus";
import { queryNoAuth } from "@/service/apis/common";
import { userOrderSave } from "@/service/apis/subScribe";
import { useStore } from "vuex";
import { useClPop } from "@/Hooks";
export default function () {
  const store = useStore();

  const { closePop, showPop } = useClPop();
  const carCardImg = ref(require("assets/imgs/carCard.png"));
  const formEl = ref(null);
  const carTypeCode = ref("");
  const isLoad = ref(false);
  const form = reactive({
    pName: "", //车主姓名
    cNum: "", //车牌号码
    cjNum: "", //车架号码
    LDENum: "", //发动机号码
    runCar: "", //是否运营车辆
    carType: "", //车辆类型
    loginTime: "", //注册日期
    certificateTime: "", //发证日期
  });
  // 查询车型
  const doQueryNoAuth = async () => {
    const res = await queryNoAuth({
      name: form.carType,
    });
    if (res.code === "0") {
      if (res.data.length !== 0) {
        carTypeCode.value = res.data.code;
      }
    }
    return;
  };
  const is_oper = computed(() => {
    let r = form.runCar.includes("否") ? "0" : "1";
    return r;
  });
  // 生成订单
  const doUserOrderSave = async () => {
    const param = {
      user_name: form.pName,
      license_plates: form.cNum,
      vin: form.cjNum,
      fdjh: form.LDENum,
      is_oper: is_oper.value,
      car_type_code: carTypeCode.value,
      car_type_name: form.carType,
      registr_date: form.loginTime,
      send_date: form.certificateTime,
      vehicle_id: chooseInfo.value.vechicleId,
      voucher_id: chooseInfo.value.voucherId,
      type: orderType.value,
      valid_type: 2,
      reserve_date: subscribeDate.value,
    };
    const res = await userOrderSave(param);
    if (res?.code === "0") {
      store.commit("setOrderId", res.data);
    } else {
      ElMessage({
        type: "warning",
        message: "系统故障，请重新登录！",
      });
    }
  };
  const data = {
    type: 3,
    base64: "",
  };
  const actionUrl = FILE_URL;
  const validateRunCar = (rule, value, callback) => {
    const reg = new RegExp("[/^是$|^否&/]");
    if (!value) {
      callback(new Error('请输入是否检测车辆！'))
    } else if (!reg.test(value)) {
      callback(new Error('必须输入是或否'))
    } else {
      callback()
    }
  }
  const rules = {
    pName: [
      {
        required: true,
        message: "请输入车主姓名",
        trigger: "blur",
      },
    ],
    cNum: [
      {
        required: true,
        message: "请输入车牌号码",
        trigger: "blur",
      },
    ],
    cjNum: [
      {
        required: true,
        message: "请输入车架号码",
        trigger: "blur",
      },
    ],
    LDENum: [
      {
        required: true,
        message: "请输入发动机号码",
        trigger: "blur",
      },
    ],
    runCar: [
      { validator: validateRunCar, trigger: 'blur' }
    ],
    carType: [
      {
        required: true,
        message: "请输入车辆类型",
        trigger: "blur",
      },
    ],
    loginTime: [
      {
        type: "date",
        required: true,
        message: "请选择发证日期",
        trigger: "change",
      },
    ],
    certificateTime: [
      {
        type: "date",
        required: true,
        message: "请选择注册日期",
        trigger: "change",
      },
    ],
  };
  const submit = async (formEl, info) => {
    if (!formEl) return;
    await formEl.validate(async (valid, fields) => {
      if (valid) {
        try {
          console.log('提交');
          await doQueryNoAuth();
          await doUserOrderSave();
          closePop();
          showPop(4);
        } catch (err) {
          console.log(err);
        }
      } else {
        console.log("error submit!", fields);
      }
    });
  };
  // 文件上传成功
  const successLoad = (response) => {
    isLoad.value = false
    if (response.code == 0) {
      const { data } = response;
      carCardImg.value = data.imgUrl;
      form.pName = data.owner;
      form.cNum = data.licensePlate;
      form.cjNum = data.vin;
      form.LDENum = data.fdjhm;
      form.runCar = data.resultCode === "0" ? "否" : "是";
      form.carType = data.carTypeName;
      form.loginTime = data.zcrq;
      form.certificateTime = data.birthDay;
      nextTick(() => formEl.value.validate());
    } else {
      ElMessage({
        type: "warning",
        message: response.msg,
      });
    }
  };
  // 文件上传中
  const onProgress = () => {
    isLoad.value = true
  };
  const chooseInfo = computed(() => store.state.chooseInfo);
  const orderType = computed(() => store.state.orderType);
  const subscribeDate = computed(() => store.state.date);

  return {
    carCardImg,
    form,
    formEl,
    rules,
    submit,
    data,
    actionUrl,
    successLoad,
    isLoad,
    onProgress
  };
}

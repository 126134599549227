import { createRouter, createWebHashHistory } from 'vue-router'
import h5_routes from './h5_routes'
const routes = [
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    name: ' Home',
    path: '/home',
    redirect: '/',
    component: () => import('../views/Home.vue'),
    meta: {
      showHeader: true,
      showFooter: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('../views/Login.vue'),
    meta: {
      showHeader: true,
      showFooter: true,
      // keepAlive: true,
    }
  },
  {
    name: 'Subscribe',
    path: '/subscribe',
    component: () => import('../views/Subscribe.vue'),
    meta: {
      showHeader: true,
      showFooter: true,
    }
  },
  {
    name: 'MyOrder',
    path: '/myorder',
    component: () => import('../views/MyOrder.vue'),
    meta: {
      showHeader: true,
      showFooter: true,
      keepAlive: true,
    }
  },
  {
    name: 'Question',
    path: '/question',
    component: () => import('../views/Question.vue'),
    meta: {
      showHeader: true,
      showFooter: true,
      keepAlive: true,
    }
  },
  ...h5_routes
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

import { reactive,computed,watch } from "vue";
import { useStore,mapGetters } from "vuex";

export default function(){
     const store = useStore()
     const map = new BMapGL.Map('map');
     map.centerAndZoom(new BMapGL.Point(116.331398, 39.897445), 12);
     // 将地址解析结果显示在地图上，并调整地图视野
     function doGetPoint(address) {
     const myGeo = new BMapGL.Geocoder();
      myGeo.getPoint(
        address,
        function (point) {
          if (point) {
            map.centerAndZoom(point, 16);
            map.enableScrollWheelZoom(true); //开启鼠标缩放
            map.addOverlay(new BMapGL.Marker(point, { title: address }));
          } else {
            ElMessage({
              type: 'error',
              message: '您选择的地址没有解析到结果！'
            })
          }
        },
        map
      );
     }
     const address = computed(() =>  store.getters.getPosition)
     const isSearch = computed(() =>  store.state.search)
     watch(isSearch,(newV,oldV) => {
        doGetPoint(address.value)
     },{deep:true,immediate:true})
      //创建地址解析器实例
      
}
import { computed } from "@vue/reactivity";
import { onMounted, onUnmounted, ref, toRefs, watch } from "vue";
import { useClPop } from "@/Hooks";
import { useStore } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      defaulte: "1200px"
    },
    height: {
      type: String,
      default: "961px"
    }
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var pop = computed(function () {
      return store.state.loginPop;
    });
    watch(pop, function (newV) {
      if (newV !== 0) {
        document.body.style.height = "100vh";
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.height = "auto";
        document.body.style.overflow = "auto";
      }
    }, {
      deep: true
    });

    var _toRefs = toRefs(props),
        show = _toRefs.show,
        width = _toRefs.width,
        height = _toRefs.height;

    var isShow = ref(null); // watch(props,(newV,oldV) => {
    //    isShow.value = newV.show
    // },{deep: true, immediate: true})

    var closeClick = function closeClick() {
      isShow.value = false;
      emit("closePop");
      store.commit("setLoginPop", 0);
    };

    return {
      closeClick: closeClick,
      show: show,
      width: width,
      height: height,
      isShow: isShow
    };
  }
};
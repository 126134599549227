
import { createStore } from 'vuex'

export default createStore({
  state: {
    name: '', //真实姓名
    idCard: '', //身份证号
    gender: '', //性别
    position: '', //地址
    date: '', //日期
    carType: '', //车型
    tel: '', //手机号
    password: '', //密码
    userName: '', //注册时的用户名
    token: null, 
    point: {
      lat: '22.54846',
      lng: '114.06455', 
    }, //经纬度 默认深圳市经纬度
    isLogin: false, //是否登录
    isRealName: false, //是否实名
    search: 0, //搜索点击
    loginPop: 0, //注册页面弹窗 0 没有弹窗  1 注册成功弹窗 2.填写详细信息弹窗 3.填写订单弹窗 4.付款订单 5. 注册弹窗 6.登录信息弹窗
    chooseInfo: null, //点击购买的订单详情
    orderType: null ,//订单类型 1 普通 2 599vip
    orderId: null, //订单id
    orderState: 0, //订单状态 监听订单状态，改变重新请求订单列表
  },
  mutations: {
    setPosition(state,p=''){
      state.position = p
    },
    setDate(state,d=''){
      state.date = d
    },
    setCarType(state,c=''){
      state.carType = c
    },
    setTel(state,t){
      state.tel = t
    },
    setPassword(state,p){
      console.log('password'.repeat(100),p);
      state.password = p
    },
    setUserName(state,u){
      state.userName = u
    },
    setToken(state,t){
      state.token = t
    },
    setPoint(state,p){
      state.point = p
    },
    setIsLogin(state,i){
      state.isLogin = i
    },
    setIsRealName(state,i){
      state.isRealName = i
    },
    setSearch(state){
      state.search+=1
    },
    setLoginPop(state,l){
      console.log(l)
      state.loginPop = l
    },
    setChooseInfo(state,c){
      state.chooseInfo = c
    },
    setOrderType(state,o){
      state.orderType = o
    },
    setOrderId(state,v){
      state.orderId = v
    },
    setIdCard(state,v){
      state.idCard = v
    },
    setGender(state,v){
      state.gender = v
    },
    setName(state,v){
      state.name = v
    },
    setOrderState(state){
      state.orderState += 1
    }
  },
  actions: {
  },
  getters:{
    getPosition(state){
      const arr = []
      if(state.position){
        state.position.forEach(v => arr.push(v.value))
      }
      return arr.join('/')
    }
  },
  modules: {
  }
})

import { computed, ref ,reactive,defineEmits} from "vue";
import { postSms, checkSms, register } from "@/service/apis/login";
import { useStore } from "vuex";
import { useClPop,useLogin } from '@/Hooks'
import { ElMessage } from "element-plus";

export default function () {
  const emit = defineEmits(['paySuccessed'])
  const store = useStore()
  const count = ref(180); //倒计时数字
  const showCount = ref(false); //展示倒计时
  const registerState = ref(false) //是否注册成功
  const { closePop, showPop } = useClPop()
  const { doLogin } = useLogin()
  const form = reactive({
    userName: '',
    password: '',
    tel: '',
    code: '',
    })
  const message = computed(() => {
    if (showCount.value) {
      return `已发送${count.value}`;
    } else {
      return "获取验证码";
    }
  });
  // 发送验证码
  const sendSms = async () => {
    try {
      const res = await postSms({
        tel: form.tel,
      });
      ElMessage({
        message: '短信已发送',
        type: 'success',
      })
    } catch (err) {
      console.log(err);
    }
  };
  // 短信校验
  const doCheckSms = async () => {
    const res = await checkSms({
      tel: form.tel,
      code: form.code
    })
    if (!res.data) {
      ElMessage({
        message: '验证码错误',
        type: 'error'
      })
      throw (new Error('验证码错误'))
    }
  }
  // 注册
  const doRegister = async () => {
    if(!form.userName.trim()){
      ElMessage({
        type: 'warning',
        message: '不能以空格作为用户名'
      })
      return
    }
    if(!form.password.trim()){
      ElMessage({
        type: 'warning',
        message: '不能以空格作为密码'
      })
      return
    }
    const res = await register({
      name: form.userName,
      password: form.password,
      mobile: form.tel
    })
    if (res.code == '0') {
      store.commit('setTel', form.tel)
      store.commit('setPassword', form.password)
      store.commit('setUserName', form.userName)
      store.commit('setLoginPop', 1)
      registerState.value = true
      // doLogin()
      closePop()
      showPop(1)
    }else {
      ElMessage({
        type: 'warning',
        message: res.msg
      })
    }
  }
  // 倒计时
  function countdown() {
    const timer = setInterval(() => {
      // console.log(message.value)

      count.value--;
      showCount.value = true;
      if (count.value <= 0) {
        clearInterval(timer);
        count.value = 5;
        showCount.value = false;
      }
    }, 1000);
  }
  // 提交
  const submit = async (ElForm) => {
     if(!ElForm) return
     await ElForm.validate(async (valid, fields) => {
      if (valid) {
        try {
          await doCheckSms()
          await doRegister()
        } catch (err) {
          console.log(err);
        }
      } else {
        console.log('error submit!', fields)
      }
     })

  };
  // 点击获取验证码
  const getCode = () => {
    if (!showCount.value) {
      if (form.tel) {
        sendSms();
        countdown();
      } else {
        ElMessage({
          message: '请输入手机号',
          type: 'error',
        })
      }

    }
  };
  return {
    submit,
    getCode,
    showCount,
    count,
    showCount,
    message,
    registerState,
    form,
    closePop
  };
}

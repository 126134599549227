import { computed, reactive, ref ,watch, watchEffect,defineEmits} from 'vue'
import { useStore } from 'vuex'
import { 
  wxPay,
  queryById, 
} from '@/service/apis/common'
import {
  useClPop
}from '@/Hooks'
import { ElMessage } from 'element-plus'
export default function(){
   const store = useStore()
   const step = ref(0)
   const statusC = ref(null)
   const orderInfo = reactive({
    userName: '',
    licensePlates: '',
    orderNo: '',
    voucherName: '',
    payFee: '',
    voucherAmount: '',
   })
   const codeUrl = ref('')
   const timer = ref(null)
   const {closePop} = useClPop()
   const order_id = computed(() => store.state.orderId)
   const doWxPay = async() => {
     const res = await wxPay({
      order_id: order_id.value,
      money_type: 13
     })
     if(res.code === '0'){
      codeUrl.value = res.data.codeUrl
     }else {
       ElMessage({
         type: 'warning',
         message: res.msg
       })
     }
   }

   const doQueryById = async() => {
     const res = await queryById({
       id: order_id.value
     })
     if(res.code==='0') {
       const {
        userName,
        licensePlates,
        orderNo,
        voucherName,
        payFee,
        voucherAmount,
        status
       } = res.data
       statusC.value = status
      orderInfo.userName = userName;
      orderInfo.licensePlates = licensePlates
      orderInfo.orderNo = orderNo
      orderInfo.voucherName = voucherName
      orderInfo.payFee = payFee
      orderInfo.voucherAmount = voucherAmount
     }else {
       ElMessage({
         type: 'warning',
         message: '系统错误，请重试！'
       })
       return false
     }
   }
   watchEffect(() => {
    if(codeUrl.value){
      timer.value = setInterval(async() => {
       console.log(codeUrl.value);
       const res = await queryById({
          id: order_id.value
        })
        statusC.value = res?.data?.status
       }, 1000);
    }
   })
   
   watch(statusC,(newV) => {
     if(newV === 2){
       store.commit('setOrderState')
      clearInterval(timer.value)
      ElNotification({
        title: '支付成功',
        type: 'success',
        message: "可在【我的订单】中查看",
        position: 'top-right',
      })
    
      closePop()
     }
   },{deep: true})
   const payVerify = () => {
     showPop()
     console.log(show.value);
   }
   return {
    payVerify,
    step,
    doWxPay,
    doQueryById,
    orderInfo,
    codeUrl,
    timer
   }
}



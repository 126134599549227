import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.function.name.js";
import VFoot from 'components/Footer';
import Tabbar from 'components/Tabbar';
import { useStorage } from "@/Hooks";
import { JSLWZ_USER_INFO, JSLWZ_TOKEN, JSLWZ_ACCOUNT_NUMBER } from "@/constants";
import { useStore } from 'vuex';
export default {
  components: {
    VFoot: VFoot,
    Tabbar: Tabbar
  },
  setup: function setup() {
    console.log(document.cookie, 'cookie'.repeat(100));
    console.log(useStorage(JSLWZ_ACCOUNT_NUMBER), 'JSLWZ_ACCOUNT_NUMBER'.repeat(10));
    var store = useStore();

    if (useStorage(JSLWZ_USER_INFO)) {
      var _useStorage = useStorage(JSLWZ_USER_INFO),
          _useStorage$userName = _useStorage.userName,
          userName = _useStorage$userName === void 0 ? '' : _useStorage$userName,
          _useStorage$mobile = _useStorage.mobile,
          mobile = _useStorage$mobile === void 0 ? '' : _useStorage$mobile,
          _useStorage$isRealNam = _useStorage.isRealName,
          isRealName = _useStorage$isRealNam === void 0 ? '' : _useStorage$isRealNam,
          _useStorage$idCard = _useStorage.idCard,
          idCard = _useStorage$idCard === void 0 ? '' : _useStorage$idCard,
          _useStorage$gender = _useStorage.gender,
          gender = _useStorage$gender === void 0 ? '' : _useStorage$gender,
          _useStorage$name = _useStorage.name,
          name = _useStorage$name === void 0 ? '' : _useStorage$name;

      var _useStorage2 = useStorage(JSLWZ_ACCOUNT_NUMBER),
          _useStorage2$password = _useStorage2.password,
          password = _useStorage2$password === void 0 ? '' : _useStorage2$password;

      var isReal = isRealName == '1' ? true : false;
      console.log('app'.repeat(100));
      store.commit('setUserName', userName);
      store.commit('setTel', mobile);
      store.commit('setIsLogin', true);
      store.commit('setIsRealName', isReal);
      store.commit('setIdCard', idCard);
      store.commit('setGender', gender);
      store.commit('setName', name);
      store.commit('setPassword', password);
    }

    if (useStorage(JSLWZ_TOKEN)) {
      store.commit('setToken', useStorage(JSLWZ_TOKEN));
    }
  }
};
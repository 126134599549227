// 封装请求
import axios from "axios";
import store from "@/store";
import { useStorage } from "@/Hooks";
import { JSLWZ_TOKEN} from "@/constants";
import { BASE_URL } from "@/config";
// 请求
const http = axios.create({
  baseURL: BASE_URL,
  timeout: 600000,
});
// 请求拦截
http.interceptors.request.use(
  (config) => {
    //请求头设置
    // let token = localStorage.getItem('token') || ''
    // config.headers.Authorization = token
    let { params = null } = config;
    const token = store.state.token||useStorage(JSLWZ_TOKEN);
    console.log("token".repeat(10), token);
    if (token) {
      params = { ...params, ...token };
      config.params = params;
    }
    return config;
  },
  (err) => {
    console.log(err);
  }
);
// 响应拦截
http.interceptors.response.use(
  (res) => {
    if (res.status < 200 || res.status > 300) {
      ElMessage({
        message: res.data.msg,
        type: "warning",
      });
    }
    if(res.data.code === '2'){
      store.commit('setToken',null)
    }
    return res.data;
  },
  (err) => {
    console.log(err,'error'.repeat(10));
  }
);
// 返出
export default http;

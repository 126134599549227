import { login } from "@/service/apis/login";
import { reactive, toRefs,ref, nextTick } from "vue";
import { useRouter } from "vue-router"
import { useStore } from "vuex";
import { toLine } from '@/utils';
import { JSLWZ_TOKEN,JSLWZ_ACCOUNT_NUMBER,JSLWZ_USER_INFO} from "@/constants";
import { useStorage } from "@/Hooks";
export default function(){
    const router = useRouter()
    const store = useStore()
    const form = reactive({
        tel: store.state.tel, //手机号
        password: store.state.password, //密码
        rememberme: true, //记住密码
    })
    async function doLogin (goNum){
        console.log(form);
        const {
            tel:mobile,
            password,
            rememberme
        } = toRefs(form)
       const res = await login({
        mobile: mobile.value,
        password: password.value,
        rememberme: rememberme.value - 0
       })
       console.log(res);
       if(res.code == 0){
           ElMessage({
               type:'success',
               message: '登录成功'
           })
            const  {name:userName, mobile,tokenBo,isRealName,realname,genderName,cardId} = res.data
            const isReal = isRealName == '1' ? true : false
            const keyNames = Object.keys(tokenBo)
            let obj = {}
            for(let i=0; i < keyNames.length; i++){
            let param = toLine(keyNames[i])
            obj[param] = tokenBo[keyNames[i]]
            }
           console.log(obj);
           store.commit('setUserName',userName)
           store.commit('setTel',mobile)
           store.commit('setToken',obj)
           store.commit('setIsLogin',true)
           store.commit('setIsRealName',isReal)
           store.commit('setName',realname)
           store.commit('setGender',genderName)
           store.commit('setIdCard',cardId)
           store.commit('setPassword',password)
           localStorage.removeItem(JSLWZ_TOKEN)
           localStorage.removeItem(JSLWZ_USER_INFO)
        //    localStorage.removeItem(JSLWZ_ACCOUNT_NUMBER)
           
           useStorage(JSLWZ_TOKEN,obj)
           useStorage(JSLWZ_ACCOUNT_NUMBER,{tel:form.tel,password:form.password})

           useStorage(JSLWZ_USER_INFO,{
            userName,
            mobile,
            isRealName,
            idCard: cardId,
            gender: genderName,
            name: realname,
           })
           if(goNum){
            router.go('-1')
           }
       }else{
        ElMessage({
            type:'error',
            message: res.msg
        })
       }
  }
  return {
    form,
    doLogin
  }
}
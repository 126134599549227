import { useStore } from 'vuex';
import { reserve } from '@/service/apis/subScribe'
import { reactive, computed} from 'vue';
export default function(){
    const store = useStore()
    // 监测站列表
        let stationList = reactive({
          data: []
        })
      //  获取检测站列表
        const getReserve = async(props,emit) => {
          const own_lon = store.state.point.lng;
          const own_lat = store.state.point.lat;
          const city_code = store.state?.position[1]?.code || '';
          const date = store.state.date;
          const car_type = store.state.carType;
          const is_lowest_price = props.currentType
          const page_no = props.currentPage
          const page_size = 6
          const res = await reserve({
            own_lon,
            own_lat,
            city_code,
            date,
            car_type,
            is_lowest_price,
            page_no,
            page_size
          }) 
          console.log(res,'r'.repeat(10));
          if(res.code == '0'){
            const { data } = res;
            const { list } = data; 
            stationList.data = list;
            emit('totalInfo',data);  
          }
        }
       
        const list = computed(() => stationList.data)
        return {
            getReserve,
            list
        }
}
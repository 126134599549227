import "core-js/modules/es.function.name.js";
import { reactive, ref, watchEffect, computed } from "vue";
import { useClPop } from "@/Hooks";
import { useStore } from "vuex";
import { JSLWZ_TOKEN, JSLWZ_USER_INFO, JSLWZ_ACCOUNT_NUMBER } from "@/constants";
import ClPop from "../ClPop.vue";
export default {
  components: {
    ClPop: ClPop
  },
  setup: function setup() {
    var store = useStore();

    var _useClPop = useClPop(),
        closePop = _useClPop.closePop;

    var name = computed(function () {
      return store.state.name;
    });
    var userName = computed(function () {
      return store.state.userName;
    });
    var idCard = computed(function () {
      return store.state.idCard;
    });
    var tel = computed(function () {
      return store.state.tel;
    });
    var gender = computed(function () {
      return store.state.gender;
    });
    var info = reactive({
      name: name,
      userName: userName,
      idCard: idCard,
      tel: tel,
      gender: gender
    });

    var quit = function quit() {
      store.commit("setToken", null); // store.commit("setPassword", '');

      localStorage.removeItem(JSLWZ_TOKEN);
      localStorage.removeItem(JSLWZ_USER_INFO);
      localStorage.removeItem(JSLWZ_ACCOUNT_NUMBER);
      closePop();
    };

    return {
      info: info,
      quit: quit,
      closePop: closePop
    };
  }
};